import React from 'react';

export default function CurrentCameraIcon({variant}) {
  if (variant === 'outline') {
    return (
      <svg width="18" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img">
        <path d="M0 3.16667C0 1.41444 1.38963 0 3.11111 0H15.5556C17.277 0 18.6667 1.41444 18.6667 3.16667V15.8333C18.6667 17.5856 17.277 19 15.5556 19H3.11111C1.38963 19 0 17.5856 0 15.8333V3.16667ZM3.11111 2.36444C2.67556 2.36444 2.32296 2.72333 2.32296 3.16667V15.8333C2.32296 16.2767 2.67556 16.6356 3.11111 16.6356H15.5556C15.9911 16.6356 16.3437 16.2767 16.3437 15.8333V3.16667C16.3437 2.72333 15.9911 2.36444 15.5556 2.36444H3.11111ZM25.677 14.7989V4.18L20.243 6.67111V4.07444L25.3037 1.75222C25.5526 1.64667 25.8222 1.58333 26.0919 1.58333C27.1496 1.58333 28.0207 2.47 28.0207 3.54667V15.4744C28.0207 16.5511 27.1496 17.4378 26.0919 17.4378C25.8222 17.4378 25.5526 17.3744 25.3037 17.2689L20.243 14.9467V12.35L25.677 14.8411V14.7989Z" fill="currentColor"/>
        <path d="M15.806 9.24669V9.66892C15.806 9.90114 15.6194 10.0911 15.3912 10.0911L14.4356 10.0911C14.1452 12.4345 12.32 14.2922 10.0178 14.5878V15.5778C10.0178 15.81 9.83112 16 9.60298 16H9.18816C8.96001 16 8.77335 15.81 8.77335 15.5778V14.5878C6.47112 14.2922 4.64594 12.4345 4.35557 10.0911L3.41481 10.0911C3.18667 10.0911 3 9.90114 3 9.66892V9.24669C3 9.01447 3.18667 8.82447 3.41481 8.82447L4.35557 8.82445C4.64594 6.48112 6.47112 4.62334 8.77335 4.32779L8.77332 3.42222C8.77332 3.19 8.95999 3 9.18814 3H9.60295C9.8311 3 10.0178 3.19 10.0178 3.42222L10.0178 4.32779C12.32 4.62334 14.1452 6.48112 14.4356 8.82445L15.3912 8.82447C15.6194 8.82447 15.806 9.01447 15.806 9.24669ZM13.0045 9.45779C13.0045 7.43112 11.3867 5.78445 9.39557 5.78445C7.40446 5.78445 5.78668 7.43112 5.78668 9.45779C5.78668 11.4845 7.40446 13.1311 9.39557 13.1311C11.3867 13.1311 13.0045 11.4845 13.0045 9.45779ZM11.8222 9.45779C11.8222 10.83 10.723 11.9278 9.39557 11.9278C8.06816 11.9278 6.9689 10.8089 6.9689 9.45779C6.9689 8.10668 8.06816 6.98779 9.39557 6.98779C10.723 6.98779 11.8222 8.10668 11.8222 9.45779ZM10.3911 9.45779C10.3911 8.88779 9.93483 8.44445 9.39557 8.44445C8.85631 8.44445 8.40001 8.9089 8.40001 9.45779C8.40001 10.0067 8.85631 10.4711 9.39557 10.4711C9.93483 10.4711 10.3911 10.0067 10.3911 9.45779Z" fill="currentColor" />
      </svg>
    )
  }
  else {
    return (
      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img">
        <path d="M6.91932 3.78946C6.20353 3.34736 5.3088 3.34736 4.56318 3.78946C3.84739 4.23157 3.40002 5.05262 3.40002 5.93683C3.40002 6.82104 3.84739 7.6421 4.56318 8.0842C5.27897 8.52631 6.17371 8.52631 6.91932 8.0842C7.63511 7.6421 8.08248 6.82104 8.08248 5.93683C8.08248 5.05262 7.63511 4.23157 6.91932 3.78946ZM7.0088 6.72631C6.74038 7.19999 6.26318 7.51578 5.72634 7.51578C5.1895 7.51578 4.71231 7.23157 4.44388 6.72631C4.17546 6.25262 4.17546 5.65262 4.44388 5.17894C4.71231 4.70525 5.1895 4.38946 5.72634 4.38946C6.26318 4.38946 6.74038 4.67368 7.0088 5.17894C7.27722 5.65262 7.27722 6.25262 7.0088 6.72631Z" fill="currentColor" />
        <path d="M9.45439 0H1.87895C0.835088 0 0 0.88421 0 1.98947V9.97895C0 11.0842 0.835088 11.9684 1.87895 11.9684H9.42456C10.4684 11.9684 11.3035 11.0842 11.3035 9.97895V1.98947C11.3035 0.88421 10.4684 0 9.42456 0H9.45439ZM9.84211 6.53684H9.21579C8.97719 8.08421 7.81403 9.31579 6.32281 9.6V10.2632C6.32281 10.6105 6.05439 10.8947 5.72632 10.8947C5.39825 10.8947 5.12982 10.6105 5.12982 10.2632V9.6C3.66842 9.34737 2.50526 8.11579 2.23684 6.53684H1.61053C1.28246 6.53684 1.01404 6.25263 1.01404 5.90526C1.01404 5.55789 1.28246 5.27368 1.61053 5.27368H2.23684C2.47544 3.72632 3.6386 2.49474 5.12982 2.21053V1.54737C5.12982 1.2 5.39825 0.915789 5.72632 0.915789C6.05439 0.915789 6.32281 1.2 6.32281 1.54737V2.21053C7.78421 2.46316 8.94737 3.69474 9.21579 5.27368H9.84211C10.1702 5.27368 10.4386 5.55789 10.4386 5.90526C10.4386 6.25263 10.1702 6.53684 9.84211 6.53684Z" fill="currentColor" />
        <path d="M16.5228 1.10525C16.2246 0.915773 15.8368 0.947352 15.5386 1.1684L12.7053 3.15788L12.2877 3.44209V8.5263L12.7053 8.81051L15.5386 10.8C15.8368 11.021 16.1947 11.021 16.5228 10.8631C16.8211 10.6737 17.0298 10.3579 17.0298 9.97893V1.98946C17.0298 1.61051 16.8509 1.29472 16.5228 1.10525Z" fill="currentColor" />
      </svg>
    );
  }
}
